import React, { useEffect, useMemo, useState } from "react";
import countryList from "react-select-country-list";
import * as service from "./service";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLanguageModal } from "../../../Redux/LanguageModal/languageModalSlice";
import Translate from "../../../Components/MultiLanguage/Translate";

const Accounts = ({
  profileWill,
  accountDetails,
  handleFileChange,
  errors,
  handleUpdate,
  values,
  setErrors,
  onSubmitHandler,
}) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const selectedLanguage = useSelector((state) => state?.languageModal?.value?.selectedLanguage);

  const countries = useMemo(() => countryList().getData(), []);
  const [currentCountry, setCurrentCountry] = useState({});
  const [countryListOption, setCountryListOption] = useState([]);

  const dispatch = useDispatch();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    if (accountDetails) {
      setCountryListOption(
        countries?.map((item, index) => {
          const { value, label } = item;
          if (value !== accountDetails?.country_code) {
            return (
              <option key={index} value={value}>
                {label}
              </option>
            );
          } else {
            setCurrentCountry({ label, value });
          }
        })
      );
    }
  }, [accountDetails]);

  const passwordSubmitHandler = (e) => {
    e.preventDefault();
    if (passwordValidation() === true) {
      updatePassword();
    } else {
      toast.error(<Translate textKey={'check_fields_error'} />, {
        position: "bottom-center",
      });
    }
  };

  const passwordValidation = () => {
    let validationStatus = true;
    let errors = {};
    let passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;

    const { newPassword, cPassword, confirmPassword } = values;
    if (!cPassword) {
      errors.cPassword = <Translate textKey={'current_password_required'} />;
      validationStatus = false;
    }

    if (!newPassword) {
      errors.newPassword =<Translate textKey={'password_required'}/>;
      validationStatus = false;
    } else if (!newPassword?.match(passwordRegex)) {
      errors.newPassword =
        <Translate textKey={'password_validation_message'}/>;
      validationStatus = false;
    }

    if (!confirmPassword) {
      errors.confirmPassword = <Translate textKey={'confirm_password_required'}/>;
      validationStatus = false;
    } else if (confirmPassword !== newPassword) {
      errors.confirmPassword = <Translate textKey={'password_matching_error_message'}/>;
      validationStatus = false;
    }
    setErrors(errors);
    return validationStatus;
  };
  const updatePassword = async () => {
    try {
      const response = await service.updatePassword(appInfo, values);

      if (response?.data?.success === true) {
        toast.success(<Translate textKey={'password_changed_message'}/>, {
          position: "bottom-center",
        });
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (err) {
     
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  const languageHandler = () => {
    let data = {
      isOpen: true,
      selectedLanguage: selectedLanguage ? selectedLanguage : null,
    };
    dispatch(
      getLanguageModal({
        languageModal: data,
      })
    );
  };

  return (
    <div className="bottom">
      <div className="profileSection">
        <div className="imageContainer">
          {profileWill ? (
            <img src={profileWill} alt="Profile" />
          ) : accountDetails?.user_image ? (
            <img src={accountDetails?.user_image} alt="Profile" />
          ) : (
            <img src={require("../../../assets/Images/user_avatar.png")} alt="Avatar" />
          )}
          <input type="file" id="file" accept="image/*" onChange={(e) => handleFileChange(e)} />

          <label htmlFor="file"><Translate  textKey={'change_picture'}/></label>
        </div>
      </div>
      <div className="contactDetails">
        <div className="row">
          <div className="inputField noMargin">
            <label htmlFor="email"><Translate textKey={'email_address'} /> </label>
            <input type="email" value={values?.email || ""} disabled id="email" />
          </div>
        </div>

        <div className="row">
          <h1 className="heading"><Translate textKey ={'change_password_caps'} /> </h1>
          <div className="inputField">
            <label htmlFor="currentPassword"><Translate textKey={'current_password'} /></label>
            <input
              type="password"
              name="cPassword"
              id="currentPassword"
              value={values?.cPassword || ""}
              onChange={(item) => handleUpdate(item)}
            />
            {errors?.cPassword && <span className="error">{errors?.cPassword}</span>}
          </div>

          <div className="inputField">
            <label htmlFor="newPassword"> <Translate textKey={'new_password'} /></label>
            <input
              type="password"
              name="newPassword"
              id="newPassword"
              value={values?.newPassword || ""}
              onChange={(item) => handleUpdate(item)}
            />
            {errors?.newPassword && <span className="error">{errors?.newPassword}</span>}
          </div>

          <div className="inputField">
            <label htmlFor="confirmPassword"><Translate textKey={'confirm_password'} /></label>
            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              value={values?.confirmPassword || ""}
              onChange={(item) => handleUpdate(item)}
            />
            {errors?.confirmPassword && <span className="error">{errors?.confirmPassword}</span>}
          </div>
          <div className="buttonContainer">
            <button
              disabled={values?.newPassword && values?.cPassword && values?.confirmPassword ? false : true}
              onClick={(e) => passwordSubmitHandler(e)}
            >
             <Translate  textKey={'update_password'} />
            </button>
          </div>
        </div>

        <div className="row">
          <h1 className="heading"><Translate textKey={'edit_info_caps'} /></h1>
          <div className="inputField">
            <label htmlFor="fname"><Translate textKey={'first_name'} /></label>
            <input
              name="firstName"
              value={values?.firstName || ""}
              type="text"
              id="fname"
              onChange={(item) => handleUpdate(item)}
            />
            {errors?.firstName && <span className="error text-danger">{errors?.firstName}</span>}
          </div>

          <div className="inputField">
            <label htmlFor="lname"><Translate textKey={'last_name'} /></label>
            <input
              name="lastName"
              type="text"
              id="lname"
              value={values?.lastName || ""}
              onChange={(item) => handleUpdate(item)}
            />
            {errors?.lastName && <span className="error text-danger">{errors?.lastName}</span>}
          </div>

          <div className="inputField">
            <label htmlFor="text"> <Translate textKey={'country'} /> </label>
            <select name="country" id="country" onChange={(item) => handleUpdate(item)}>
              <option value={currentCountry?.value}>{currentCountry?.label}</option>
              {countryListOption}
            </select>
            {errors?.country && <span className="error text-danger">{errors?.country}</span>}
          </div>

          <div className="inputField noMargin">
            <label htmlFor="phone"> <Translate textKey={'phone'} /></label>
            <input
              name="phone"
              type="tel"
              id="phone"
              value={values?.phone || ""}
              onChange={(item) => handleUpdate(item)}
            />
          </div>
          {projectInfo?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED === "true" && (
            <span className="language" onClick={languageHandler}>
             <Translate  textKey={'change_language'}/>
            </span>
          )}
          <div className="buttonContainer">
            <button onClick={(e) => onSubmitHandler(e)}><Translate textKey={'update_settings'}/></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accounts;
