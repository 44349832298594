import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Loading/Loading";
import MainLoading from "../Loading/MainLoading";
import ShowCard from "../ShowCard/ShowCard";
import * as service from "./service";
import { useWindowWidth } from "@react-hook/window-size";
import { rowItemCount } from "../../utils/utils";
import useScrollPosition from "../ScrollPosition/useScrollPosition";
import Translate from "../MultiLanguage/Translate"
//react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

//components
import CardStyle from "../cards/CardStyle";
import BreadCrumbWidget from "../BreadcrumbWidget";

//function\


import EventDetailsPage from "../../Screens/EventDetailsScreenModal/EventDetailPage";
import MovieDetail from "../../Screens/ShowDetailsScreen/DetailPage";
const CommonPage = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const isModalOpen = useSelector((state) => state?.moreInfoOther?.value);
  const isEventMoreInfo = useSelector((state) => state?.eventMoreInfo?.value);

  const [shows, setShows] = useState([]);
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [img, setImg] = useState(true);
  const [rowItemsClass, setRowItemsClass] = useState("");
  const [showInvisibleShow, setShowInvisibleShow] = useState(false);
  const [sliceCount, setSliceCount] = useState(20)
  const windowSize = useWindowWidth();
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const [loading, setLoading] = useState(true);
  const careers = params?.get("career");
  const type = location?.state?.type;
  const navigate = useNavigate();
  const containerRef = useRef(null)
  const scrollPosition = useScrollPosition();

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  useEffect(() => {
    window.scroll(0, 0);
    fetchCategoryDetails();
  }, [location]);


  useEffect(() => {
    if (
      projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  useEffect(() => {
    if (!loading && sliceCount < shows?.shows?.length) {
      lazyLoad()
    }
  }, [loading, scrollPosition, shows])

  useEffect(() => {
    if (windowSize > 980) {
      if (projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT) {
        setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT))
      } else {
        setRowItemsClass("rowCount4")
      }
    } else {
      setRowItemsClass("")
    }
    if (shows) {
      if (Object.entries(shows)?.length > 0) {
        if (projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT === "3" && windowSize > 980) {
          if (shows?.shows?.length % 3 !== 0) {
            setShowInvisibleShow(true)
          }
        } else if (projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT === "4" && windowSize > 980) {
          if (shows?.shows?.length % 4 !== 0) {
            setShowInvisibleShow(true)
          }
        } else if (projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT === "5" && windowSize > 980) {
          if (shows?.shows?.length % 5 !== 0) {
            setShowInvisibleShow(true)
          }
        } else if (projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT === "6" && windowSize > 980) {
          if (shows?.shows?.length % 6 !== 0) {
            setShowInvisibleShow(true)
          }
        } else if (projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT === "7" && windowSize > 980) {
          if (shows?.shows?.length % 7 !== 0) {
            setShowInvisibleShow(true)
          }
        }
        else if (projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT === "8" && windowSize > 980) {
          if (shows?.shows?.length % 8 !== 0) {
            setShowInvisibleShow(true)
          }
        }
        else {
          setShowInvisibleShow(false)
        }

        if (shows?.shows?.length === 1) {
          setShowInvisibleShow(true)
        }
      }
    }

  }, [projectInfo, windowSize, shows]);

  const fetchCategoryDetails = async () => {
    setLoading(true);
    try {
      const itemResponse = await service.getAllShowsOfCategory(
        appInfo,
        careers
      );
      if (itemResponse?.status === 200) {
        setShows(itemResponse?.data?.data);
        setImg(false);
        setLoading(false);
      }
    } catch (err) {
      // toast.error(err?.response?.data?.message, {
      //   position: "top-center",
      // });
      setShows([]);
      setLoading(false);
    }
  };
  const lazyLoad = () => {
    const containerHeight = containerRef?.current?.clientHeight;
    if (scrollPosition + 2200 >= containerHeight) {
      setSliceCount(sliceCount + 20)
    }
  }
  if (loading) {
    return <MainLoading />;
  }


  return (
    <>
      <div className="commonPage" ref={containerRef}>
        {isModalOpen?.isModal && !isModalOpen?.isFromSearch && (
          <MovieDetail />
        )}
        {
          isEventMoreInfo?.isModal &&
          <EventDetailsPage />
        }
        {(shows?.shows?.length === 0) | (Object.entries(shows).length === 0) ? (
          <h1
            style={{
              color: "#E72A31",
              display: "flex",
              justifyContent: "center",
              padding: "60px 0",
            }}
          >
            <Translate textKey={'no_data_found'} />
          </h1>
        ) : (
          <div className="wrapper">
            {/* {shows?.banners ? (
              <div className="bgImageContainer">
                <div className="top inside">
                  <h1 className="heading">{shows?.category_name}</h1>
                  <p className="desc">{shows?.synopsis}</p>
                </div>
                <img src={shows?.banner} alt="BgImage" />

                <div className="bottomGradient" />
              </div>
            ) : ( */}
              <div className="top">
                <BreadCrumbWidget title={shows?.category_name} synopsis={shows?.synopsis} imgUrl={shows?.banner} />             
                {/* <h1 className="heading">{shows?.category_name}</h1>
                <p className="desc">{shows?.synopsis}</p> */}
              </div>
            {/* )} */}

            <div className="bottom">
              {/* <div className="itemsContainer">
                {shows?.shows?.slice(0,sliceCount)?.map((item, index) => (
                  <div
                  className={
                    thumbnailOrientation === `PORTRAIT`
                      ? `items portrait ${rowItemsClass}`
                      : `items landscape ${rowItemsClass}`
                  }
                  >
                    <ShowCard
                      data={item}
                      key={index}
                      imageUrl={img}
                      season={false}
                      metaData={true}
                      cardHover={true}
                      modalPageNavigation={false}
                    />
                  </div>
                ))}
                 {showInvisibleShow &&
              shows?.shows?.slice(0, 1).map((item, index) => (
                <div
                  className={
                    thumbnailOrientation === `PORTRAIT`
                      ? `items portrait hidden ${rowItemsClass}`
                      : `hidden items landscape ${rowItemsClass}`
                  }
                >
                  <ShowCard
                    data={item}
                    key={index}
                    imageUrl={true}
                    season={false}
                    metaData={true}
                    modalPageNavigation={false}
                  />
                </div>
              ))}
              </div> */}
              <div className={shows?.banner?"section-padding":"section-padding-noimg"}>
                <Container fluid>
                  <div className="card-style-grid ">
                    <Row className="row row-cols-xl-5 row-cols-md-4 row-cols-sm-3 row-cols-1 row">
                      {shows?.shows?.slice(0, sliceCount)?.map((item, index) => {
                        let itemLink;
                        if (type === "UPCOMING_EVENT" || type === "LIVE_EVENTS" || careers == 'live_events' ) {
                          itemLink = `/event/${item?.event_id}`
                        } else if (type === "PODCASTS") {
                          itemLink = `/podcast/${item?.podcast_id}`
                        } else{
                          itemLink = {
                            pathname: `/show-details/${item?.vanity_url}`,
                            state: { item: item }
                        };}
                        return(
                        <Col key={index} className="mb-4" >
                          <div>
                            <CardStyle
                              image={item?.logo || item?.logo_thumb}
                              title={item?.show_name || item?.video_title}
                              movieTime={item?.duration_text}
                              watchlistLink="/playlist"
                              link={itemLink}
                              show_Id={item?.show_id}
                              share={true}
                              vanity_url={item?.vanity_url}
                              data={item}
                            />
                          </div>
                        </Col>
                      )})}
                      {/* {viewAll.map((item, index) => (
                <Col key={index} className="mb-4">
                  <CardStyle
                    image={item.image}
                    title={item.title}
                    movieTime={item.movieTime}
                    watchlistLink="/playlist"
                    link="/movies-detail"
                  />
                </Col>
              ))} */}
                    </Row>
                  </div>
                </Container>
              </div>
            </div>
          </div>
        )}
      </div>

    </>
  );
};

export default CommonPage;
