import { Fragment, memo, useEffect, useState } from "react";
import SectionSlider from "../slider/SectionSlider";
import ContinueWatchCard from "../cards/ContinueWatchCard";

const ContinueWatching = memo(({ data }) => {
  const [title] = useState("Continue Watching");
  const [watching, setWatching] = useState([]);

  useEffect(() => {
    setWatching(data?.shows)
  }, [data]);

  return (
    <Fragment>
      <SectionSlider
        title={title}
        list={watching}
        className="continue-watching-block section-padding-top bottom_margin_space continueWatching"
        slidesPerView={5}
        data={data}
        styleElement={false}
      >
        {(data) => (
          <ContinueWatchCard
            imagePath={data.logo_thumb}
            progressValue={data.watched_percentage}
            dataLeftTime={data.show_name}
            link={`/show-details/${data?.vanity_url}`}
          ></ContinueWatchCard>
        )}
      </SectionSlider>
    </Fragment>
  );
});

ContinueWatching.displayName = ContinueWatching;
export default ContinueWatching;
