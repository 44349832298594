import axios from "axios";
import { fetchApiData, fetchApiDataV2, updateApiDataV2 } from "../../utils/utils";

export const getVideoDetails = async (appInfo,id) =>{
    const api =`video/${id}`
    // if(appInfo?.projectDetails?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED==="true"){
        return await fetchApiDataV2(appInfo,api)
    // }else{
    //     return await fetchApiData(appInfo,api)
    // }
}

export const getLiveInfo = async (appInfo)=>{
    const api="channel/list"
    if(appInfo?.projectDetails?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED==="true"){
        return await fetchApiData(appInfo,api)
    }else{
        return await fetchApiData(appInfo,api)
    }
    
  }

