import React, { memo, Fragment, useState, useEffect } from "react";

//components
import SectionSlider from "../slider/SectionSlider";
import TopTenCard from "../../Components/cards/TopTenCard";

//function

const TopTenMoviesToWatch = memo(({ data }) => {
  const [title, setTitle] = useState("");
  const [shows, setShows] = useState([]);
  let count = 0;

  useEffect(() => {
    setShows(data?.shows)
    setTitle(data?.category_name)
   
  }, [data]);
  

  return (
    <Fragment>
      <SectionSlider title={title} list={shows} className="top-ten-block" data={data}>
        {(shows) => {
          count += 1;
          return (
            <TopTenCard
              imagePath={shows.logo}
              countValue={count}
              // image={data?.logo}
              // title={data?.show_name}
              // movieTime={data?.duration_text}
              link={`/show-details/${shows?.vanity_url}`}
            />
          )
        }}
      </SectionSlider>
    </Fragment>
  );
});

TopTenMoviesToWatch.displayName = "TopTenMoviesToWatch";
export default TopTenMoviesToWatch;
