import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CardStyle from '../../Components/cards/CardStyle'
import { useLocation } from 'react-router-dom'

function EpisodeList() {
    const location = useLocation()
    const episodes=  location?.state[0]
    

    useEffect(()=>{
      window.scroll(0,0)
    },[])
  return (
    <div className='episodeListPage'>
        <div className="wrapper">
        <h2 className='title'>{location?.state[1]}</h2>
        <div className="section-padding">
                <Container fluid>
                  <div className="card-style-grid ">
                    <Row className="row row-cols-xl-7 row-cols-md-6 row-cols-sm-4 row-cols-2 row">
                      {episodes?.map((item, index) => (
                        <Col key={index} className="mb-4" >
                          <div>
                            <CardStyle
                              image={item?.thumbnail}
                              title={item?.show_name || item?.video_title}
                              movieTime={item?.duration_text}
                              link={`/videos/${item?.vanity_url}`}
                              show_Id={item?.show_id}
                              vanity_url={item?.vanity_url}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Container>
              </div>
        </div>
    </div>
  )
}

export default EpisodeList