import React, { useState, useEffect, useRef } from "react";
import ShowCard from "../../Components/ShowCard/ShowCard";
import * as service from "./service";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../Components/Loading/Loading";
import MainLoading from "../../Components/Loading/MainLoading";
import { rowItemCount } from "../../utils/utils";
import { useWindowWidth } from "@react-hook/window-size";
import useScrollPosition from "../../Components/ScrollPosition/useScrollPosition";
import Translate from "../../Components/MultiLanguage/Translate";
import CardStyle from "../../Components/cards/CardStyle";
import { getSelectedChannel } from "../../Redux/SelectedChannel/selectedChannelSlice";
import { getSelectedSchedule } from "../../Redux/SelectedScheduleChannel/selectedScheduleChannelSlice";
import { useNavigate } from "react-router-dom";

const Search = () => {
  const isModalOpen = useSelector((state) => state?.moreInfoOther?.value);
  const [searchData, setSearchData] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [redirectUrl, setRedirectUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const accessToken = useSelector((state) => state.accessToken?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const params = new URLSearchParams(location?.search);
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [rowItemsClass, setRowItemsClass] = useState("");
  const [showInvisibleShow, setShowInvisibleShow] = useState(false);
  const [sliceCount, setSliceCount] = useState(20)
  const [searchDataCategories, setSearchDataCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState();
  const [isLive,setIsLive] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const windowSize = useWindowWidth();
  const containerRef = useRef(null)
  const scrollPosition = useScrollPosition();
  let query = params?.get("query");
  let itemLink;

  const appInfo = {
    accessToken: accessToken,
    projectDetails: projectInfo,
  };
  useEffect(() => {
    if (
      projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  useEffect(() => {
    if (!loading && sliceCount < searchData?.length) {
      lazyLoad()
    }
  }, [loading, scrollPosition, searchData])

  useEffect(() => {
    if (windowSize > 980) {
      if (projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT) {
        setRowItemsClass(
          rowItemCount(
            projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT
          )
        );
      } else {
        setRowItemsClass("rowCount6");
      }
    } else {
      setRowItemsClass("");
    }

    if (
      projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT === "3" &&
      windowSize > 980
    ) {
      if (searchData?.length % 3 !== 0) {
        setShowInvisibleShow(true);
      }
    } else if (
      projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT === "4" &&
      windowSize > 980
    ) {
      if (searchData?.length % 4 !== 0) {
        setShowInvisibleShow(true);
      }
    } else if (
      projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT === "5" &&
      windowSize > 980
    ) {
      if (searchData?.length % 5 !== 0) {
        setShowInvisibleShow(true);
      }
    } else if (
      projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT === "6" &&
      windowSize > 980
    ) {
      if (searchData?.length % 6 !== 0) {
        setShowInvisibleShow(true);
      }
    }
    else if (
      projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT === "7" &&
      windowSize > 980
    ) {
      if (searchData?.length % 7 !== 0) {
        setShowInvisibleShow(true);
      }
    } else if (
      projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT === "8" &&
      windowSize > 980
    ) {
      if (searchData?.length % 8 !== 0) {
        setShowInvisibleShow(true);
      }
    } else {
      setShowInvisibleShow(false);
    }

    if (searchData?.length === 1) {
      setShowInvisibleShow(true);
    }
  }, [projectInfo,windowSize]);

  useEffect(() => {
    window.scroll(0, 0)
    setLoading(true);
    if (query === null) {
      setSearchData(null);
      setSearchInput(null)
      setLoading(false);
    } else {
      setSearchInput(query);
      fetchShowDetails();
    }
  }, [query]);
  const fetchShowDetails = async () => {
    try {
      const response = await service?.getShows(appInfo, query, location?.state?.type);
     
      if (response?.status === 200) {
        if (response?.data?.data[0]?.category_id) {
          setSearchData(response?.data?.data[0]?.shows);
          setSearchDataCategories(response?.data?.data);
          setActiveCategory(response?.data?.data[0]?.category_name)
        }
        else {
          setSearchData(response?.data?.data);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  const lazyLoad = () => {
    const containerHeight = containerRef?.current?.clientHeight;
    if (scrollPosition + 2200 >= containerHeight) {
      setSliceCount(sliceCount + 20)
    }
  }

  const searchCategoryHandler = (item) => {

    setSearchData(null)
    setSearchData(item?.shows)
    setActiveCategory(item?.category_name)

  }
  if (loading) {
    return <MainLoading />;
  }

  return (
    <div className="searchPage" ref={containerRef}>
      {/* {isModalOpen?.isModal && !isModalOpen?.isFromSearch && (
        <DetailScreenModal />
      )} */}
      {/* <ToastContainer /> */}
      <div className="wrapper">
        <div className="top">
          <h1 className="heading"><Translate textKey={'results_caps'} /></h1>
          <p className="desc">{searchInput}</p>
        </div>
        <div className="bottom">
          <div className="categories">
            {
              searchDataCategories?.map((item, index) => (

                <button className={activeCategory === item?.category_name ? "active" : "categoryButton"} key={index} onClick={() => searchCategoryHandler(item)} >{item?.category_name}</button>
              ))
            }
          </div>
          <div className="itemsContainer">
            {searchData?.length <= 0 && (
              <h1
                style={{
                  color: "#E72A31",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Translate textKey={'no_data_found'} />
              </h1>
            )}


            {searchData?.slice(0, sliceCount)?.map((item, index) => {
              if(item.type==="VIDEO"){
                itemLink = `/videos/${item.vanity_url}`
              }else if (item.type === "EVENT"){
                itemLink = `/event/${item?.event_id}` 
              }else if (item.type === "PODCAST"){
                itemLink = `/podcast/${item.podcast_id}` 
              }else if(item.type === "SHOW"){
                itemLink = `/show-details/${item?.vanity_url}`
              }else if(item.type === "FASTCHANNEL"){
                if (projectInfo?.projectConfig?.config?.MULTI_CHANNELS_REQUIRED === "true") {
                  itemLink = "/live-channels"
                } else {
                  itemLink = "/player"
                }
              }else{
                itemLink = `/show-details/${item?.vanity_url}`
              }
              return(
              <div
                className={
                  thumbnailOrientation === `PORTRAIT`
                    ? `items portrait ${rowItemsClass}`
                    : `items landscape ${rowItemsClass}`
                }
              >
                <CardStyle
                    image={item?.logo}
                    title={item?.show_name || item?.video_title}
                    movieTime={item?.duration_text}
                    watchlistLink="/playlist"
                    link={itemLink}
                    show_Id={item?.show_id}
                    vanity_url={item?.vanity_url}
                    data={item}
                    subscriptionCheck={true}
                    isLiveCheck={itemLink === "/player" ? true :false}
                  />
              </div>
            )})}
            {/* {showInvisibleShow &&
              searchData?.map((item, index) => (
                <div
                  className={
                    thumbnailOrientation === `PORTRAIT`
                      ? `items portrait ${rowItemsClass}`
                      : `items landscape ${rowItemsClass}`
                  }
                >
                  <CardStyle
                    image={item?.logo}
                    title={item?.show_name || item?.video_title}
                    movieTime={item?.duration_text}
                    watchlistLink="/playlist"
                    link={itemLink}
                    show_Id={item?.show_id}
                    vanity_url={item?.vanity_url}
                    data={item}
                    subscriptionCheck={true}
                  />
                </div>
              ))} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
